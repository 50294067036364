// export const BASEURL =
//   process.env.REACT_APP_BASE_URL || 'https://api.flattts.com'; //'http://192.168.1.78:8080' //https://api.flattts.com:8080
export const BASEURL = 'https://api.flattts.com';
// export const BASEURL = 'http://localhost:8080';
// export const BASEURL = 'http://localhost:8080';
// export const BASEURL = 'http://192.168.1.67:8080';
export const GOOGLE_MAP_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_KEY ||
  'AIzaSyAgcJ-JZYcJwVIFeJyaAOI4b5aap7T2I3w';
export const IMG_URL = 'https://d18fng178ow3z3.cloudfront.net/';
export const CRPTO_SECRET_KEY = process.env.REACT_APP_CRPTO_SECRET_KEY;

export const nairaSymbol = '₦';
export const currencies = [
  {
    name: 'Dollar',
    symbol: '$',
    code: 'USD',
  },
  {
    name: 'Naira',
    symbol: '₦',
    code: 'NGN',
  },
  {
    name: 'Cedis',
    symbol: '¢',
    code: 'GHS',
  },
];
export const onlyCountries = [
  'bj',
  'bf',
  'cv',
  'gm',
  'gh',
  'gn',
  'gw',
  'ci',
  'lr',
  'ml',
  'mr',
  'ne',
  'ng',
  'sn',
  'sl',
  'tg',
  'us',
  'uk',
  'fr',
  'de',
  'es',
];

export const RULES_PATH = `${BASEURL}/uploads/rules/`;
export const AMENITIES_PATH = `${BASEURL}/uploads/amenities/`;
export const AUTH_BASE = '/auth';
export const PROPERTY_BASE = '/properties';
export const REVIEW_BASE = '/review';
export const NOTIFICATION_BASE = '/notification';
export const BOOKING_BASE = '/booking';
export const ADMIN_BASE = '/admin';
export const ROOM_BASE = '/room';
export const MESSAGE_BASE = '/message';
export const PAYMENT_BASE = '/payment';
export const ADMIN_SETTING_BASE = `${ADMIN_BASE}/setting`;
export const ADMIN_EMAIL_TEMPLATE_BASE = `${ADMIN_BASE}/templates`;
export const ADMIN_USERLOGGED_BASE = `${ADMIN_BASE}/userlogged`;

// ENDPOINTS FOR AUTH MODULE
export const AUTH_ENDPOINT = {
  LOGIN: `${AUTH_BASE}/login`,
  SINGUP: `${AUTH_BASE}/sign-up`,
  FORGOT_PASSWORD: `${AUTH_BASE}/forgot-password`,
  RESET_PASSWORD: `${AUTH_BASE}/reset-password`,
  EMAIL_VERIFY: `${AUTH_BASE}/verify-email`,
  CHANGE_PASSWORD: `${AUTH_BASE}/change-password`,
  UPDATE_PROFILE: `${AUTH_BASE}/update-profile`,
  CONTACT_US: `${AUTH_BASE}/contact-us`,
  ADD_FAVORITE: `${AUTH_BASE}/add-favorite`,
  FAVORITE_LIST: `${AUTH_BASE}/favorite-list`,
};

// ENDPOINTS FOR PROPERTY MODULE
export const PROPERTY_ENDPOINT = {
  ADD_PROPERTY: `${PROPERTY_BASE}/add-property`,
  MY_PROPERTY: `${PROPERTY_BASE}/my-property`,
  GET_PROPERTY: `${PROPERTY_BASE}/get-property`,
  GET_PROPERTY_FOR_EDIT: `${PROPERTY_BASE}/get-edit-property`,
  EDIT_PROPERTY: `${PROPERTY_BASE}/edit-property`,
  ALL_PROPERTY: `${PROPERTY_BASE}/all-property`,
  DELETE_PROPERTY: `${PROPERTY_BASE}/delete-property`,
  // SEARCH_PROPERTY: `${PROPERTY_BASE}/search-property`,
  GET_RULES_AMENITIES: `${PROPERTY_BASE}/rules-amenities`,
  POPULAR_SPACES: `${PROPERTY_BASE}/popular-spaces`,
  TOP_RATED_CITIES: `${PROPERTY_BASE}/top-rated-cities`,
  FETCH_CITIES: `${PROPERTY_BASE}/fetch-all-cities`,
  ADMIN_ADD_PROPERTY: `${ADMIN_BASE}/admin-add-property`,
  SHORT_LET: `${PROPERTY_BASE}/short-let`,
};

export const REVIEW_ENDPOINT = {
  ADD_REVIEW: `${REVIEW_BASE}/add-review`,
};
// ENDPOINTS FOR notification MODULE
export const NOTIFICATION_ENDPOINT = {
  NOTIFICATION_LIST: `${NOTIFICATION_BASE}/list`,
  NOTIFICATION_READ: `${NOTIFICATION_BASE}/read`,
  NOTIFICATION_DELETE: `${NOTIFICATION_BASE}/delete`,
  NOTIFICATION_DELETE_ALL: `${NOTIFICATION_BASE}/clearall`,
};

export const ROOM_ENDPOINT = {
  MEMEBER_LIST: `${ROOM_BASE}/newlist`,
  ROOM_CREATE: `${ROOM_BASE}/create`,
  GET_CONTACT_LIST: `${ROOM_BASE}/contact`,
};

export const PAYMENT_ENDPOINT = {
  ADD_PAYMENT_CARD: `${PAYMENT_BASE}/add-payment-card`,
  GET_PAYMENT_CARD: `${PAYMENT_BASE}/get-payment-card`,
  UPDATE_PAYMENT_CARD: `${PAYMENT_BASE}/update-payment-card`,
  DELETE_PAYMENT_CARD: `${PAYMENT_BASE}/delete-payment-card`,
  GET_CARD_FOR_UPDATE: `${PAYMENT_BASE}/get-edit-payment-card`,
  SET_CARD_AS_PRIMARY: `${PAYMENT_BASE}/set-primary-card`,
  SET_BANK_AS_PRIMARY: `${PAYMENT_BASE}/set-primary-bank`,
  GET_BANK_LIST: `${PAYMENT_BASE}/bank-list`,
  GET_BANK_ACCOUNT: `${PAYMENT_BASE}/get-bank-accounts`,
  DELETE_BANK_ACCOUNT: `${PAYMENT_BASE}/delete-bank-account`,
  ADD_BANK_ACCOUNT: `${PAYMENT_BASE}/add-bank-account`,
  CREATE_TRANSACTION: `${PAYMENT_BASE}/create-transaction`,
  TOKENIZED_CARD: `${PAYMENT_BASE}/tokenize-card`,
  TOKENIZED_BANK: `${PAYMENT_BASE}/tokenize-bank-on-mono`,
  GET_TRANSACTION_HISTORY: `${PAYMENT_BASE}/transaction-list`,
  SEND_WITHDRAW_REQUEST: `${PAYMENT_BASE}/send-withdraw-request`,
  GET_WITHDRAWAL_HISTORY: `${PAYMENT_BASE}/withdrawal-list`,

  //----------mono start---------------
  CREATE_TRANSACTION_MONO_PAYMENT: `${PAYMENT_BASE}/create-transaction-mono-payment`,
  //----------mono end---------------
};
export const MESSAGE_ENDPOINT = {
  MESSAGE_LIST: `${MESSAGE_BASE}/list`,
  MESSAGE_READ: `${MESSAGE_BASE}/read`,
  MESSAGE_SEND: `${MESSAGE_BASE}/send`,
};

export const BOOKING_ENDPOINT = {
  BOOK_PROPERTY: `${BOOKING_BASE}/booking-property`,
  MY_APPLY_LISTING: `${BOOKING_BASE}/fetch-apply-listing`,
  BOOKING_DETAILS: `${BOOKING_BASE}/booking-details`,
  BOOKING_REQUEST: `${BOOKING_BASE}/booking-request`,
  MANAGE_REQUEST: `${BOOKING_BASE}/manage-request`,
  UNIT_TO_PAY: `${BOOKING_BASE}/unit-to-pay`,
  UNIT_HISTORY_LIST: `${BOOKING_BASE}/unit-history-list`,
};

export const CONTENT_ENDPOINT = {
  HOME_CONTENT: `${ADMIN_BASE}/home-content`,
  AVAILABLE_SPACES_CONTENT: `${ADMIN_BASE}/available-spaces`,
  ABOUT_CONTENT: `${ADMIN_BASE}/about-us`,
  BECOME_HOST_CONTENT: `${ADMIN_BASE}/become-host`,
  ADMIN_LOGIN: `${ADMIN_BASE}/auth/login`,
  EMAIL_VERIFY_ADMIN_USER: `${ADMIN_BASE}/users/verify-email-admin-user`,
  ALL_COUNT_ROLEBASED: `${ADMIN_BASE}/users/all-count`,
  ALL_USERS_COUNT: `${ADMIN_BASE}/users/get-all-users-count`,
  ALL_USERS: `${ADMIN_BASE}/users/get-users`,
  CREATE_ADMIN_USER: `${ADMIN_BASE}/users/create-admin-user`,
  DELETE_PARTICULAR_USER: `${ADMIN_BASE}/users/delete-user`,
  ACTIVE_INACTIVE_PARTICULAR_USER: `${ADMIN_BASE}/users/active-inactive-user`,
  FETCH_PARTICULAR_USER: `${ADMIN_BASE}/users/get-one-user`,
  UPDATE_USER: `${ADMIN_BASE}/users/update-user`,
  UPDATE_PROFILE_SUPERADMIN: `${ADMIN_BASE}/auth/update-profile`,
  UPDATE_PASSWORD_SUPERADMIN: `${ADMIN_BASE}/auth/change-password`,
  GET_LOGO: `${ADMIN_BASE}/setting/get-logo`,
  UPDATE_LOGO: `${ADMIN_BASE}/setting/update-logo`,
  GET_FIRST_CONTACTUS: `${ADMIN_BASE}/setting/get-first-contactus`,
  UPDATE_FIRST_CONTACTUS: `${ADMIN_BASE}/setting/update-first-contactus`,
  GET_SECOND_CONTACTUS: `${ADMIN_BASE}/setting/get-second-contactus`,
  UPDATE_SECOND_CONTACTUS: `${ADMIN_BASE}/setting/update-second-contactus`,
  GET_THIRD_CONTACTUS: `${ADMIN_BASE}/setting/get-third-contactus`,
  UPDATE_THIRD_CONTACTUS: `${ADMIN_BASE}/setting/update-third-contactus`,
  GET_FOURTH_CONTACTUS: `${ADMIN_BASE}/setting/get-fourth-contactus`,
  UPDATE_FOURTH_CONTACTUS: `${ADMIN_BASE}/setting/update-fourth-contactus`,
  GET_EMAIL_CONFIGURE: `${ADMIN_BASE}/setting/get-email-configure`,
  UPDATE_EMAIL_CONFIGURE: `${ADMIN_BASE}/setting/update-email-configure`,
  GET_SOCIAL_MEDIA_LINK: `${ADMIN_BASE}/setting/get-socialmedia-link`,
  UPDATE_SOCIAL_MEDIA_LINK: `${ADMIN_BASE}/setting/update-socialmedia-link`,
  // FETCH_WITHDRAW_REQUEST_LIST: `${ADMIN_BASE}/payment/get-withdraw-request-list`,
  GET_LOGO_COMMON: `${ADMIN_BASE}/common/common-get-logo`,
  GET_SOCIALMEDIA_LINK_COMMON: `${ADMIN_BASE}/common/common-get-socialmedia-link`,
  GET_MODULES: `${ADMIN_BASE}/roles-permission/get-module`,
  GET_ROLES: `${ADMIN_BASE}/roles-permission/get-roles`,
  CREATE_ROLES: `${ADMIN_BASE}/roles-permission/create-roles`,
  DELETE_ROLES: `${ADMIN_BASE}/roles-permission/delete-roles`,
  FETCH_PARTICULAR_USER_MODULES: `${ADMIN_BASE}/users/get-one-adminuser-modules`,
  // FETCH_WITHDRAW_REQUEST_LIST: `${ADMIN_BASE}/payment/get-withdraw-request-list`,
  CMS_HOME_CONTENT: `${ADMIN_BASE}/cms/cms-content`,
  CMS_HOME_CONTENT_GET_DATA: `${ADMIN_BASE}/cms/cms-content-data`,
  CMS_HOME_CONTENT_ADD_UPDATE: `${ADMIN_BASE}/cms/cms-content-add-update`,
  GET_CMS_CONTENT: `${ADMIN_BASE}/cms/get-cms-content`,
  GET_ABOUTUS_INFO_CONTENT: `${ADMIN_BASE}/cms/get-aboutus-content`,
  UPDATE_ABOUTUS_INFO_CONTENT: `${ADMIN_BASE}/cms/addedit-aboutus-content`,
  GET_HOWITWORKS_CONTENT: `${ADMIN_BASE}/cms/get-howitworks-content`,
  UPDATE_HOWITWORKS_CONTENT: `${ADMIN_BASE}/cms/addedit-howitworks-content`,
};

export const ADMIN_SETTING_ENDPOINT = {
  FETCH_FAQS_LIST: `${ADMIN_SETTING_BASE}/faq-list`,
  ADD_OR_EDIT_FAQS: `${ADMIN_SETTING_BASE}/add-edit-faq`,
  GET_FAQ_FOR_EDIT: `${ADMIN_SETTING_BASE}/faq-for-edit`,
  DELETE_FAQS: `${ADMIN_SETTING_BASE}/delete-faq`,
  FETCH_AMENITIES_LIST: `${ADMIN_SETTING_BASE}/amenities-list`,
  ADD_OR_EDIT_AMENITIES: `${ADMIN_SETTING_BASE}/add-edit-amenities`,
  DELETE_AMENITIES: `${ADMIN_SETTING_BASE}/delete-amenities`,
  FETCH_RULES_LIST: `${ADMIN_SETTING_BASE}/rules-list`,
  ADD_OR_EDIT_RULES: `${ADMIN_SETTING_BASE}/add-edit-rules`,
  DELETE_RULES: `${ADMIN_SETTING_BASE}/delete-rules`,
  GET_FEATURED_CITIES: `${ADMIN_SETTING_BASE}/featured-cities-list`,
  ADD_OR_REMOVE_FEATURED_CITIES: `${ADMIN_SETTING_BASE}/add-remove-featured-cities`,
  CHANGE_CITY_IMAGE: `${ADMIN_SETTING_BASE}/change-featured-image`,
  GET_REVIEW_LIST: `${ADMIN_SETTING_BASE}/reviews-list`,
  ADD_OR_REMOVE_REVIEWS: `${ADMIN_SETTING_BASE}/add-edit-reviews`,
  DELETE_REVIEWS: `${ADMIN_SETTING_BASE}/delete-review`,
  SAVED_AS_POPULAR: `${ADMIN_SETTING_BASE}/saved-as-popular`,
  GET_ALL_CITIES_FOR_MAP: `${ADMIN_SETTING_BASE}/cities-for-map`,
  REMOVE_FROM_POPULAR: `${ADMIN_SETTING_BASE}/remove-from-popular`,
  POPULAR_SPACES_LIST: `${ADMIN_SETTING_BASE}/popular-spaces-list`,
  FETCH_CONTACTUS_LIST: `${ADMIN_SETTING_BASE}/get-contactus`,
  ADD_OR_EDIT_CONTACTUS: `${ADMIN_SETTING_BASE}/add-edit-contactus`,
  DELETE_CONTACTUS: `${ADMIN_SETTING_BASE}/delete-contactus`,
};

export const ADMIN_EMAIL_TEMPLATE_ENDPOINT = {
  FETCH_EMAIL_TEMPLATE_LIST: `${ADMIN_EMAIL_TEMPLATE_BASE}/email-template-list`,
  UPDATE_EMAIL_TEMPLATE: `${ADMIN_EMAIL_TEMPLATE_BASE}/update-email-template`,
};

export const PROPERTY_SUPERADMIN_ENDPOINT = {
  FETCH_ALL_PROPERTY: `${ADMIN_BASE}/property/get-user-property`,
  FETCH_ALL_UNIT_HISTORY: `${ADMIN_BASE}/property/get-unit-history-renter`,
  GET_ALL_PROPERTIES_LIST: `${ADMIN_BASE}/property/get-property-list`,
  GET_BOOKED_PROPERTIES_LIST: `${ADMIN_BASE}/property/get-booked-property`,
  GET_AVAILABLE_PROPERTIES_LIST: `${ADMIN_BASE}/property/get-available-property`,
  // FETCH_WITHDRAW_REQUEST_LIST: `${ADMIN_BASE}/payment/get-withdraw-request-list`,
  UPDATE_DELETE_STATUS: `${ADMIN_BASE}/property/change-delete-status`,
  DELETE_PROPERTY: `${ADMIN_BASE}/property/delete`,
  PROPERTIES_COUNT: `${ADMIN_BASE}/property/property-counts`,
  ADMIN_ADD_PROPERTY: `${ADMIN_BASE}/property/add-property`,
  ALL_COUNT_PROPERTYBASED: `${ADMIN_BASE}/property/get-property-count`,
  ALL_RECENT_BOOKED_PROPERTY: `${ADMIN_BASE}/property/get-recent-booking-list`,
  ACTIVE_INACTIVE_PARTICULAR_PROPERTY: `${ADMIN_BASE}/property/active-inactive-property`,
};

export const ADMIN_PAYMENT_ENDPOINT = {
  FETCH_WITHDRAW_REQUEST_LIST: `${ADMIN_BASE}/payment/get-withdraw-request-list`,
  FETCH_ALL_TRANSACTION_LIST: `${ADMIN_BASE}/payment/get-all-transaction-list`,
  CREATE_TRANASFERED: `${ADMIN_BASE}/payment/create-transfer`,
  FINALIZE_TRANASFERED: `${ADMIN_BASE}/payment/finalize-transfer`,
  FETCH_PAYOUT_HISTORY: `${ADMIN_BASE}/payment/get-payout-list`,
  GET_TRANSFERED_BY_ID: `${ADMIN_BASE}/payment/get-transfered-byid`,
  FETCH_PAYOUT_BALANCE: `${ADMIN_BASE}/payment/get-payout-balance`,
};

export const ADMIN_USERLOGGED_ENDPOINT = {
  ADD_USERLOGGED: `${ADMIN_USERLOGGED_BASE}/add`,
  GET_USERLOGGED: `${ADMIN_USERLOGGED_BASE}/get`,
};

export const USER_ROLES = ['landlord', 'renter', 'realtor', 'propertyManager'];

export const PROPERTY_MANAGEMENT_ROLES = [
  'landlord',
  'realtor',
  'propertyManager',
];
