import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

const initialState = {
  currency: '',
};

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    updateCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
});

export function useCurrencyStore() {
  const currency = useSelector((state) => state.currency.currency);
  const dispatch = useDispatch();

  return {
    currency,
    updateCurrency: (payload) => dispatch(updateCurrency(payload)),
  };
}

export const { updateCurrency } = currencySlice.actions;

export default currencySlice.reducer;
