import { useRoutes } from 'react-router-dom';
import {
  PrivateRoute,
  PublicRoute,
  ForRenterRoute,
  ForLandlordRoute,
  PrivateRouteAdmin,
  PublicRouteAdmin,
  lazyRoute,
} from './utils/PrivateRoute';
import { useAuthStore } from './redux/features/authSlice';
import { io } from 'socket.io-client';
import { BASEURL } from './utils/constant';
import { useEffect, lazy } from 'react';

// import ListPage from './admin/pages/MasterCrud/List';
// import RentPage from './admin/pages/MasterCrud/Rent';
// import AboutUsPage from './admin/pages/MasterCrud/AboutUs';

import { PermissionModules } from './utils/PrivateRoutesPermission';

const AdminDashboardLayout = lazyRoute(
  () => import('./layouts/admin-layout'),
  'AdminDashboardLayout'
);
const AdminLayout = lazyRoute(
  () => import('./layouts/admin-layout'),
  'AdminLayout'
);
const SignupLayout = lazyRoute(
  () => import('./layouts/signup-layout'),
  'SignupLayout'
);
const MainLayout = lazyRoute(
  () => import('./layouts/main-layout'),
  'MainLayout'
);
const DashboardLayout = lazyRoute(
  () => import('./layouts/dashboard-layout'),
  'DashboardLayout'
);
const SignUp = lazyRoute(() => import('./pages/auth/sign-up'), 'SignUp');
const Login = lazyRoute(() => import('./pages/auth/login'), 'Login');
const Page404 = lazyRoute(() => import('./pages/not-found/Page404'), 'Page404');
const ForgotPassword = lazyRoute(
  () => import('./pages/auth/forgot-password'),
  'ForgotPassword'
);
const ResetPassword = lazyRoute(
  () => import('./pages/auth/reset-password'),
  'ResetPassword'
);
const Home = lazyRoute(() => import('./pages/home'), 'Home');
const AvailableSpaces = lazyRoute(
  () => import('./pages/available-spaces'),
  'AvailableSpaces'
);
const BecomeAHost = lazyRoute(
  () => import('./pages/become-host'),
  'BecomeAHost'
);
const AboutUs = lazyRoute(() => import('./pages/about-us'), 'AboutUs');
const Faq = lazyRoute(() => import('./pages/faq'), 'Faq');
const Error = lazyRoute(() => import('./pages/error-page'), 'Error');
const PropertyDetails = lazyRoute(
  () => import('./pages/property-details'),
  'PropertyDetails'
);
const Dashboard = lazyRoute(
  () => import('./pages/dashboard-pages/dashboard'),
  'Dashboard'
);
const Message = lazyRoute(
  () => import('./pages/dashboard-pages/message'),
  'Message'
);
const AccountDetails = lazyRoute(
  () => import('./pages/dashboard-pages/account-details'),
  'AccountDetails'
);
const Settings = lazyRoute(
  () => import('./pages/dashboard-pages/settings'),
  'Settings'
);
const Payment = lazyRoute(
  () => import('./pages/dashboard-pages/payment'),
  'Payment'
);
const Notification = lazyRoute(
  () => import('./pages/dashboard-pages/notification'),
  'Notification'
);
const FavouritePlacePage = lazyRoute(
  () => import('./pages/dashboard-pages/favourite-place'),
  'FavouritePlacePage'
);
const UnitHistoryPage = lazyRoute(
  () => import('./pages/dashboard-pages/unit-history'),
  'UnitHistoryPage'
);
const AddPaymentMethod = lazyRoute(
  () => import('./pages/dashboard-pages/add-payment-method'),
  'AddPaymentMethod'
);
const AddBankAccounts = lazyRoute(
  () => import('./pages/dashboard-pages/add-bank-accounts'),
  'AddBankAccounts'
);
const PaymentMmethod = lazyRoute(
  () => import('./pages/dashboard-pages/payment-method'),
  'PaymentMmethod'
);
const PaymentDetailLandlord = lazy(() =>
  import('./pages/dashboard-pages/payment/PaymentDetailsLandlord')
);
const AddProperty = lazy(() => import('./pages/dashboard-pages/add-property'));
const EditProperty = lazy(() =>
  import('./pages/dashboard-pages/edit-property')
);
const AdminLogin = lazy(() => import('./admin/pages/Authentication/Login'));
const AdminForgetPasswordPage = lazy(() =>
  import('./admin/pages/Authentication/ForgetPassword')
);
const AdminResetPasswordPage = lazy(() =>
  import('./admin/pages/Authentication/ResetPasswordPage')
);
const AdminDashboard = lazy(() => import('./admin/pages/AdminDashboard'));
const UserListPage = lazy(() => import('./admin/pages/Users'));
const UserDetails = lazy(() => import('./admin/pages/Users/UserDetails'));
const PropertyDetailsAdmin = lazy(() =>
  import('./admin/pages/Properties/property-details')
);
const PropertyListPage = lazy(() => import('./admin/pages/Properties'));
const PaymentPage = lazy(() => import('./admin/pages/Payments'));
const Chat = lazy(() => import('./admin/pages/Chat'));
const PaymentDetailPage = lazy(() =>
  import('./admin/pages/Payments/PaymentDetails')
);
const AdminProfile = lazy(() => import('./admin/pages/Profile'));
const ErrorAdmin = lazy(() => import('./admin/pages/Error'));
const SettingsPage = lazy(() => import('./admin/pages/Settings/WebsiteInfo'));
const FaqsPage = lazy(() => import('./admin/pages/Settings/Faqs'));
const AmenitiesPage = lazy(() => import('./admin/pages/Settings/Amenities'));
const RulesPage = lazy(() => import('./admin/pages/Settings/Rules'));
const ExploreRentalsPage = lazy(() =>
  import('./admin/pages/Settings/ExploreRentals')
);
const FeaturedCitiesPage = lazy(() =>
  import('./admin/pages/Settings/FeaturedCities')
);
const ReviewsPage = lazy(() => import('./admin/pages/Settings/Reviews'));
const Email = lazy(() => import('./admin/pages/Email'));
const HomePage = lazy(() => import('./admin/pages/MasterCrud/Home'));
const HowItWorks = lazy(() =>
  import('./admin/pages/MasterCrud/List/HowItWorks')
);
const AboutUsList = lazy(() =>
  import('./admin/pages/MasterCrud/AboutUs/AboutUsList')
);
const AdminNotification = lazy(() => import('./admin/pages/Notification'));
const VirtualTour = lazy(() => import('./pages/virtual-tour'));
const AdminVirtualTour = lazy(() => import('./admin/pages/VirtualTour'));
const ScrapeProperties = lazy(() => import('./admin/pages/ScrapeProperties'));
const ShortLet = lazy(() => import('./pages/short-let'));

function Router() {
  let { loginInfo, loginAdminInfo } = useAuthStore();

  // if its on header not in router
  const notificationSocket = io(`${BASEURL}`);
  useEffect(() => {
    let id = loginInfo
      ? loginInfo._id
      : loginAdminInfo
      ? loginAdminInfo._id
      : null;

    notificationSocket.on('connect', () => {
      notificationSocket.emit('join', id);
    });
    return () => {
      notificationSocket.disconnect();
    };
  }, [notificationSocket]);

  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <ForLandlordRoute>
              <Home />
            </ForLandlordRoute>
          ),
          index: true,
        },
        {
          path: 'available-spaces',
          element: (
            <ForLandlordRoute>
              <AvailableSpaces />
            </ForLandlordRoute>
          ),
        },
        {
          path: 'short-let',
          element: (
            <ForLandlordRoute>
              <ShortLet />
            </ForLandlordRoute>
          ),
        },
        {
          path: 'become-a-host',
          element: (
            <ForLandlordRoute>
              <BecomeAHost />
            </ForLandlordRoute>
          ),
        },
        {
          path: 'about-us',
          element: <AboutUs />,
        },
        {
          path: 'faq',
          element: <Faq />,
        },
        {
          path: 'property-details/:id',
          element: <PropertyDetails />,
        },
        {
          path: '3d-virtual-tour',
          element: <VirtualTour />,
        },
      ],
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '/',
      element: (
        <PublicRoute>
          <SignupLayout />
        </PublicRoute>
      ),
      children: [
        {
          path: 'sign-up',
          element: <SignUp />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: 'error',
      element: <Error />,
    },
    {
      path: '/',
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              element: <Dashboard />,
              index: true,
            },
            {
              path: 'add-property',
              element: (
                <ForRenterRoute>
                  <AddProperty />
                </ForRenterRoute>
              ),
            },
            {
              path: 'edit-listing',
              element: (
                <ForRenterRoute>
                  <EditProperty />
                </ForRenterRoute>
              ),
            },
            {
              path: 'favourite-place',
              element: <FavouritePlacePage />,
            },
            {
              path: 'unit-history',
              element: <UnitHistoryPage />,
            },
          ],
        },
        {
          path: 'message',
          element: <Message />,
        },
        {
          path: 'account-details',
          element: <AccountDetails />,
        },
        {
          path: 'settings',
          element: <Settings />,
        },
        {
          path: 'payment',
          children: [
            {
              element: <Payment loginInfo={loginInfo} />,
              index: true,
            },
            {
              path: 'add-payment-method', // not run
              element: (
                <ForLandlordRoute>
                  <AddPaymentMethod />
                </ForLandlordRoute>
              ),
            },
            {
              path: 'payment-method',
              element: (
                <ForLandlordRoute>
                  <PaymentMmethod />
                </ForLandlordRoute>
              ),
            },
            {
              path: 'add-bank-accounts',
              element: (
                <ForRenterRoute>
                  <AddBankAccounts />
                </ForRenterRoute>
              ),
            },
            {
              path: 'p_details',
              element: (
                <ForRenterRoute>
                  <PaymentDetailLandlord />
                </ForRenterRoute>
              ),
            },
          ],
        },
        {
          path: 'notification',
          element: <Notification />,
        },
      ],
    },
    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        {
          path: '',
          element: (
            <PublicRouteAdmin>
              <AdminLogin />
            </PublicRouteAdmin>
          ),
          index: true,
        },
        {
          path: 'forgot-password',
          element: <AdminForgetPasswordPage />,
        },
        {
          path: 'reset-password',
          element: <AdminResetPasswordPage />,
        },
        {
          path: 'error',
          element: <ErrorAdmin />,
        },
        {
          path: '',
          element: (
            <PrivateRouteAdmin>
              <AdminDashboardLayout />
            </PrivateRouteAdmin>
          ),
          children: [
            {
              path: 'dashboard',
              element: <AdminDashboard />,
              index: true,
            },
            {
              path: 'tour',
              element: <AdminVirtualTour />,
            },
            {
              path: 'scrape',
              element: <ScrapeProperties />,
            },
            {
              path: 'user-list',
              element: (
                <PermissionModules permissionType={'Users'}>
                  <UserListPage />
                </PermissionModules>
              ),
            },
            {
              path: 'user-details',
              element: <UserDetails />,
            },
            {
              path: 'property-list',
              element: (
                <PermissionModules permissionType={'Properties'}>
                  <PropertyListPage />
                </PermissionModules>
              ),
            },
            {
              path: 'property-details/:id',
              element: <PropertyDetailsAdmin />,
            },
            {
              path: 'profile',
              element: <AdminProfile />,
            },
            {
              path: 'payment',
              element: (
                <PermissionModules permissionType={'Payment'}>
                  <PaymentPage />
                </PermissionModules>
              ),
            },
            {
              path: 'payment-details',
              element: <PaymentDetailPage />,
            },
            {
              path: 'email-templates',
              element: (
                <PermissionModules permissionType={'Email templates'}>
                  <Email />
                </PermissionModules>
              ),
            },
            // Chat
            {
              path: 'chat',
              element: (
                <PermissionModules permissionType={'Chat'}>
                  <Chat />
                </PermissionModules>
              ),
            },
            {
              path: 'admin-notification',
              element: (
                <PermissionModules permissionType={'notification'}>
                  <AdminNotification />
                </PermissionModules>
              ),
            },
            // Setting pages
            {
              path: 'settings',
              element: (
                <PermissionModules permissionType={'Website info'}>
                  <SettingsPage />
                </PermissionModules>
              ),
            },
            {
              path: 'faqs',
              element: (
                <PermissionModules permissionType={'Faqs'}>
                  <FaqsPage />
                </PermissionModules>
              ),
            },
            {
              path: 'amenities',
              element: (
                <PermissionModules permissionType={'Amenities'}>
                  <AmenitiesPage />
                </PermissionModules>
              ),
            },
            {
              path: 'rules',
              element: (
                <PermissionModules permissionType={'Rules'}>
                  <RulesPage />
                </PermissionModules>
              ),
            },
            {
              path: 'explore-rentals',
              element: (
                <PermissionModules permissionType={'Explore rentals'}>
                  <ExploreRentalsPage />
                </PermissionModules>
              ),
            },
            {
              path: 'featured-cities',
              element: (
                <PermissionModules permissionType={'Featured cities'}>
                  <FeaturedCitiesPage />
                </PermissionModules>
              ),
            },
            {
              path: 'reviews',
              element: (
                <PermissionModules permissionType={'Reviews'}>
                  <ReviewsPage />
                </PermissionModules>
              ),
            },
            // Master Crud
            {
              path: 'home',
              element: (
                <PermissionModules permissionType={'Home page'}>
                  <HomePage />
                </PermissionModules>
              ),
            },
            {
              path: 'list',
              element: (
                <PermissionModules permissionType={'List page'}>
                  <HomePage />
                </PermissionModules>
              ),
            },
            {
              path: 'rent',
              element: (
                <PermissionModules permissionType={'Rent page'}>
                  <HomePage />
                </PermissionModules>
              ),
            },
            {
              path: 'about-us',
              element: (
                <PermissionModules permissionType={'Aboutus page'}>
                  <HomePage />
                </PermissionModules>
              ),
            },
            {
              path: 'how-it-works',
              element: <HowItWorks />,
            },
            {
              path: 'about-us-list',
              element: <AboutUsList />,
            },
          ],
        },
      ],
    },
  ]);
}

export default Router;
